
import { defineComponent } from 'vue'
import Modal from 'bootstrap/js/dist/modal'

import { User, UserProvider, UserRole, UserUpdateAttributes } from '@/types/User'

enum Game {
  ULTIMATUM = 'ultimatum',
  ZINCIT = 'zinc-it',
  ANCHORING = 'anchoring',
  OUTPSIDER = 'outpsider'
}

export default defineComponent({
  name: 'UserEditModal',
  emits: [
    'success'
  ],
  data () {
    return {
      modal: undefined,
      error: undefined,
      disabled: false,
      game: Game.ULTIMATUM,
      user: {
        id: undefined,
        role: undefined,
        email: undefined,
        provider: undefined,
        tag: {
          name: undefined
        },
        created: undefined,
        modified: undefined
      }
    } as {
      modal?: Modal,
      error?: string,
      disabled: boolean,
      game: Game,
      user: {
        id?: number,
        role?: UserRole,
        email?: string,
        provider?: UserProvider,
        tag: {
          name?: string | null
        }
        created?: string,
        modified?: string
      }
    }
  },
  computed: {
    roles (): string[] {
      return Object.values(UserRole)
    },
    games (): string[] {
      return Object.values(Game)
    }
  },
  methods: {
    async open (id: number) {
      this.disabled = true
      this.error = undefined
      this.loadUser(await User.getById(id))
      if (!this.modal) {
        this.modal = new Modal(this.$refs.modal as HTMLElement, {})
      }
      this.modal.show()
    },
    async updateUser () {
      this.disabled = true
      this.user.tag.name = this.user.tag.name ? this.user.tag.name : null
      try {
        this.loadUser(await User.update(this.user.id!, this.user as UserUpdateAttributes))
        this.success()
      } catch (error) {
        console.log(error)
        this.error = (error as Error).message
        this.disabled = false
      }
    },
    loadUser (user?: User) {
      this.user.id = user ? user.id : undefined
      this.user.email = user ? user.email : undefined
      this.user.provider = user ? user.provider : undefined
      this.user.role = user ? user.role : undefined
      this.user.tag.name = user?.tag?.name ? user.tag.name : undefined
      this.user.created = user ? user.created.toLocaleString() : undefined
      this.user.modified = user ? user.modified.toLocaleString() : undefined
      this.disabled = false
    },
    success () {
      if (this.modal) {
        this.modal.hide()
      }
      this.disabled = false
      this.$emit('success')
    },
    async resetGame () {
      this.disabled = true
      try {
        if (this.user.id) {
          switch (this.game) {
            case Game.ULTIMATUM:
              await User.resetUltimatumById(this.user.id)
              break
            case Game.ZINCIT:
              await User.resetZincitById(this.user.id)
              break
            case Game.ANCHORING:
              await User.resetAnchoringById(this.user.id)
              break
            case Game.OUTPSIDER:
              await User.resetOutpsiderById(this.user.id)
              break
          }
        }
      } catch (error) {
        console.log(error)
        this.error = (error as Error).message
      } finally {
        this.disabled = false
      }
    }
  },
  beforeUnmount () {
    if (this.modal) {
      this.modal.dispose()
    }
  }
})
